<template>
  <v-container>
    <v-card-title>
      <span class="headline">{{action==='edit'?record.toolName:$t('reference-dialogs.create-cat-tool')}}</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field v-if="action==='edit'" v-model="record.toolName" :label="$t('reference-dialogs.cat-tool-name')" clearable/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field v-if="action==='create'" v-model="newRecord.toolName" :label="$t('reference-dialogs.cat-tool-name')" clearable
                          v-on:change="$emit('new-record',newRecord)"/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>

<script>
  import {ValidationProvider, extend, ValidationObserver} from 'vee-validate';
  import {required} from "vee-validate/dist/rules";

  extend('required', {
    ...required,
    message: 'The field is required'
  });
  export default {
    components: {ValidationProvider, ValidationObserver},
    name: "CatToolFieldset",
    props: ['record', 'action'],
    data() {
      return {
        newRecord: {},
      }
    },
  }
</script>

<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
