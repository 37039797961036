var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.action==='edit'?_vm.record.fullPairName:_vm.$t('reference-dialogs.create-lang-pair')))])]),_c('v-divider'),_c('v-card-text',[(_vm.action==='edit')?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('reference-dialogs.full-pair-name'),"clearable":""},model:{value:(_vm.record.fullPairName),callback:function ($$v) {_vm.$set(_vm.record, "fullPairName", $$v)},expression:"record.fullPairName"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3369168471)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('reference-dialogs.short-pair-name'),"clearable":""},model:{value:(_vm.record.shortPairName),callback:function ($$v) {_vm.$set(_vm.record, "shortPairName", $$v)},expression:"record.shortPairName"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4294199415)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('reference-dialogs.source-lang'),"clearable":""},model:{value:(_vm.record.sourceLang),callback:function ($$v) {_vm.$set(_vm.record, "sourceLang", $$v)},expression:"record.sourceLang"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,480744474)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('reference-dialogs.target-lang'),"clearable":""},model:{value:(_vm.record.targetLang),callback:function ($$v) {_vm.$set(_vm.record, "targetLang", $$v)},expression:"record.targetLang"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3229088410)})],1)],1):_vm._e(),(_vm.action==='create')?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Full pair name","clearable":""},on:{"change":function($event){return _vm.$emit('new-record',_vm.newRecord)}},model:{value:(_vm.newRecord.fullPairName),callback:function ($$v) {_vm.$set(_vm.newRecord, "fullPairName", $$v)},expression:"newRecord.fullPairName"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3613410578)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Short pair name","clearable":""},on:{"change":function($event){return _vm.$emit('new-record',_vm.newRecord)}},model:{value:(_vm.newRecord.shortPairName),callback:function ($$v) {_vm.$set(_vm.newRecord, "shortPairName", $$v)},expression:"newRecord.shortPairName"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,333129234)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Source language","clearable":""},on:{"change":function($event){return _vm.$emit('new-record',_vm.newRecord)}},model:{value:(_vm.newRecord.sourceLang),callback:function ($$v) {_vm.$set(_vm.newRecord, "sourceLang", $$v)},expression:"newRecord.sourceLang"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,841551140)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Target language","clearable":""},on:{"change":function($event){return _vm.$emit('new-record',_vm.newRecord)}},model:{value:(_vm.newRecord.targetLang),callback:function ($$v) {_vm.$set(_vm.newRecord, "targetLang", $$v)},expression:"newRecord.targetLang"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2651756836)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }