<template>
  <Loader v-if="loading" type="references"></Loader>
  <v-row v-else-if="!loading">
    <v-container>
      <v-tabs grow show-arrows continuous slider-size="4" centered icons-and-text>
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab v-for="tab in tabs" :key="tab.tabNr">
          {{ tab.tabTitle }}
          <v-icon :color="tab.iconColor">{{ tab.tabIcon }}</v-icon>
        </v-tab>

        <v-tab-item>
          <LanguageTab :langPairs="langPairs" :loading="loading"></LanguageTab>
        </v-tab-item>

        <v-tab-item>
          <TopicsTab :topics="topics" :loading="loading"></TopicsTab>
        </v-tab-item>

        <v-tab-item>
          <OrderTypesTab :orderTypes="orderTypes" :loading="loading"></OrderTypesTab>
        </v-tab-item>

        <v-tab-item>
          <CatToolsTab :catTools="catTools" :loading="loading"></CatToolsTab>
        </v-tab-item>

        <v-tab-item>
          <AgenciesTab :agencies="agencies" :loading="loading"></AgenciesTab>
        </v-tab-item>

        <v-tab-item>
          <CalculationTypesTab :calculationTypes="calculationTypes" :loading="loading"></CalculationTypesTab>
        </v-tab-item>

        <v-tab-item>
          <EmailTemplatesTab :templates="templates" :loading="loading"></EmailTemplatesTab>
        </v-tab-item>

        <v-tab-item v-for="tab in tabs" :key="tab.tabNr"></v-tab-item>
      </v-tabs>
    </v-container>
  </v-row>
</template>

<script>
  import LanguageTab from "./LanguageTab";
  import TopicsTab from "./TopicsTab";
  import OrderTypesTab from "./OrderTypesTab";
  import CatToolsTab from "./CatToolsTab";
  import AgenciesTab from "./AgenciesTab";
  import EmailTemplatesTab from "./EmailTemplateTab";
  import CalculationTypesTab from "./CalculationTypesTab";
  import Loader from "../../components/Loaders/Loader";
  import {mapState} from "pinia";
  import {useAgencies} from "../../stores/agenciesStore";

  const agencyStore = useAgencies()

  export default {
    name: "References",
    components: {
      CalculationTypesTab,
      EmailTemplatesTab,
      LanguageTab,
      TopicsTab,
      OrderTypesTab,
      CatToolsTab,
      AgenciesTab,
      Loader
    },
    data() {
      return {
        loading: false,
        langPairs: [],
        topics: [],
        orderTypes: [],
        calculationTypes: [],
        catTools: [],
        templates: [],
        tabs: [{
          tabNr: 0,
          tabTitle: this.$t('reference-tabs.languages'),
          tabIcon: "mdi-translate",
          iconColor: "red"
        },
          {
            tabNr: 1,
            tabTitle: this.$t('reference-tabs.topics'),
            tabIcon: "mdi-head-lightbulb-outline",
            iconColor: "blue"
          },
          {
            tabNr: 2,
            tabTitle: this.$t('reference-tabs.order-types'),
            tabIcon: "mdi-card-bulleted-settings-outline",
            iconColor: "green"
          },
          {
            tabNr: 3,
            tabTitle: this.$t('reference-tabs.cat-tools'),
            tabIcon: "mdi-hammer-wrench",
            iconColor: "orange"
          },
          {
            tabNr: 4,
            tabTitle: this.$t('reference-tabs.agencies'),
            tabIcon: "mdi-domain",
            iconColor: "brown"
          },
          {
            tabNr: 5,
            tabTitle: this.$t('reference-tabs.calc-types'),
            tabIcon: "mdi-calculator-variant-outline",
            iconColor: "pink"
          },
          {
            tabNr: 6,
            tabTitle: this.$t('reference-tabs.mail-templates'),
            tabIcon: "mdi-email-newsletter",
            iconColor: "deep-purple"
          },
          {
            tabNr: 7,
            tabTitle: this.$t('reference-tabs.currencies'),
            tabIcon: "mdi-currency-usd",
            iconColor: "teal"
          }
        ],
      }
    },
    computed: {
      ...mapState(useAgencies, ['agencies']),
    },
    methods: {
      updateNeededCheck() {
        this.loading = true
        if (!this.agencies.length) {
          agencyStore.getAgencyList()
        }
        this.loading = false
      },
    },
    created() {
      this.updateNeededCheck()
      this.getLanguageList()
      this.getTopicList()
      this.getOrderTypes()
      this.getCatToolList()
      this.getAllTemplates()
      this.getAllCalculationTypes()
    }
  }
</script>
