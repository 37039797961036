<template>
  <v-container>
    <ReferenceSearchTab :search="search" v-on:search="performSearch" :open-dialog="editDialog"
                        v-on:open-create="createItem" :objectName="objectName"></ReferenceSearchTab>
    <EditDialog v-bind:open-dialog="editDialog" v-on:close-dialog="closeDialog()" :objectType="objectType"
                :record="record" :imgName="imgName" v-on:save-record="saveRecord($event)" :action="action"
                v-on:create-record="createRecord($event)"></EditDialog>
    <v-data-table
      :loading="loading"
      dense
      item-key="templateId"
      loader-height="5"
      loading-text="Loading templates... Please, wait..."
      no-data-text="No templates found"
      multi-sort
      :headers="headers"
      :items="templates"
      :search="search"
      :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions: [ 5, 10, 15, 50, -1]}">
      <template v-slot:item.isActive="{ item }">
        <v-switch inset v-on:change="activateItem(item.templateId, item.active)"
                  v-model="item.active">
        </v-switch>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import ReferenceSearchTab from "./ReferenceSearchTab";
  import EditDialog from "./reference-dialogs/EditDialog";
  import {AXIOS} from "../../../http-commons";

  let moment = require('moment');
  export default {
    components: {ReferenceSearchTab, EditDialog},
    props: ['templates', 'loading'],
    name: "EmailTemplatesTab",
    methods: {
      performSearch(searchValue) {
        this.search = searchValue
      },
      closeDialog() {
        this.editDialog = false
      },
      editItem(item) {
        this.editDialog = true
        this.objectType = 'template'
        this.imgName = 'editTopicDialog.jpg'
        this.record = item
        this.action = 'edit'
      },
      createItem() {
        this.editDialog = true
        this.objectType = 'template'
        this.imgName = 'editEmailTemplateDialog.jpg'
        this.action = 'create'
      },
      activateItem(templateId, active) {
        let params = new URLSearchParams();
        params.append('isActive', active);
        AXIOS.put(`/api/activateEmailTemplate/` + templateId, params)
          .then(response => {
            this.response = response.data;
            this.succUpdatedNotification()
          })
      },
      saveRecord(record) {
        let params = new URLSearchParams();
        params.append('templateName', record.templateName);
        params.append('description', record.description);
        params.append('active', record.active);
        params.append('deleted', record.deleted);
        AXIOS.put(`/api/updateEmailTemplate/` + record.templateId, params)
          .then(response => {
            this.response = response.data;
            this.editDialog = false;
            this.succUpdatedNotification(response);
          })
      },
      createRecord(record) {
        let params = new URLSearchParams();
        params.append('templateName', record.templateName);
        params.append('description', record.description);
        params.append('active', 'true');
        params.append('deleted', 'false');
        AXIOS.post(`/api/createEmailTemplate/`, params)
          .then(response => {
            this.response = response.data;
            this.editDialog = false;
            this.succCreatedNotification(response);
            this.getNewRecord(this.response.templateId)
          })
      },
      getNewRecord(id) {
        AXIOS.get('/api/getEmailTemplateById/' + id)
          .then(response => {
            let record = response.data;
            this.templates.push(record)
          })
      },
      succCreatedNotification() {
        this.$message({
          message: 'Email template was created successfully',
          type: 'success',
          dangerouslyUseHTMLString: true,
          duration: 3000
        });
      },
      succUpdatedNotification() {
        this.$message({
          message: 'Email template was updated successfully',
          type: 'success',
          dangerouslyUseHTMLString: true,
          duration: 3000
        });
      },
    },
    data() {
      return {
        objectName:'Email template',
        action: '',
        editDialog: false,
        objectType: '',
        imgName: '',
        record: null,
        search: '',
        // objectFields: null,
        headers: [
          {
            text: this.$t('tables.active'),
            align: 'start',
            value: 'isActive',
            class: "primary--text",
          },
          {
            text: this.$t('tables.templateName'),
            value: 'templateName',
            class: "primary--text",
          },
          {text: this.$t('tables.description'), value: 'description', class: "primary--text"},
          {text: this.$t('tables.actions'), value: 'actions', class: "primary--text", sortable: false},
        ],
      }
    },
  }
</script>

<style scoped>

</style>
