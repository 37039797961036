<template>
  <v-container>
    <ReferenceSearchTab :search="search" v-on:search="performSearch" :objectName="objectName"></ReferenceSearchTab>
    <v-data-table
      :loading="loading"
      dense
      item-key="agencyId"
      loader-height="5"
      :loading-text="$t('loadings.loading-agencies')"
      :no-data-text="$t('loadings.no-agencies-found')"
      multi-sort
      :headers="headers"
      :items="agencies"
      :search="search"
      :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions: [ 5, 10, 15, 50, -1]}">
      <template v-slot:item.isActive="{ item }">
        <v-switch inset v-on:change="activateItem(item.agencyId, item.active)"
                  v-model="item.active">
        </v-switch>
      </template>
      <template v-slot:item.agencyName="{ item }">
        <router-link :to="{ path: '/agency/' + item.agencyId}" class="no-underline-link">
          {{item.agencyName}}
        </router-link>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import ReferenceSearchTab from "./ReferenceSearchTab";
  import {AXIOS} from "../../../http-commons";

  let moment = require('moment');
  export default {
    components: {ReferenceSearchTab},
    props: ['agencies', 'loading'],
    name: "AgenciesTab",
    methods: {
      performSearch(searchValue) {
        this.search = searchValue
      },
      activateItem(agencyId, active) {
        let action = active ? 'activate' : 'deactivate';
        let params = new URLSearchParams();
        params.append('agencyId', agencyId);
        params.append('isActive', active);
        AXIOS.put(`/api/activateAgency`, params)
          .then(response => {
            this.response = response.data;
            this.userNotification(response, action, this.objectName);
          })
      },
    },
    data() {
      return {
        objectName: 'Agency',
        search: '',
        headers: [
          {
            text: this.$t('tables.active'),
            align: 'start',
            value: 'isActive',
            class: "primary--text",
          },
          {
            text: this.$t('tables.agencyName'),
            value: 'agencyName',
            class: "primary--text",
          },
        ],
      }
    },
  }
</script>

<style scoped>
  .no-underline-link {
    text-decoration: none;
  }
</style>
