var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.action==='edit'?_vm.record.calculationTypeName:_vm.$t('reference-dialogs.create-calc-type')))])]),_c('v-divider'),_c('v-card-text',[(_vm.action==='edit')?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('reference-dialogs.calc-type'),"clearable":""},model:{value:(_vm.record.calculationTypeName),callback:function ($$v) {_vm.$set(_vm.record, "calculationTypeName", $$v)},expression:"record.calculationTypeName"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1997135769)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('p',{staticStyle:{"visibility":"hidden"}},[_vm._v(_vm._s(_vm.defineType))]),_c('v-radio-group',{attrs:{"row":""},on:{"change":function($event){return _vm.selectType()}},model:{value:(_vm.calcType),callback:function ($$v) {_vm.calcType=$$v},expression:"calcType"}},_vm._l((_vm.options),function(option){return _c('v-radio',{key:option.id,attrs:{"label":option.label,"value":option.calcType}})}),1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.calcType==='isPerSymbol')?_c('v-text-field',{attrs:{"label":_vm.$t('reference-dialogs.spp'),"clearable":""},model:{value:(_vm.record.symbolsPerPage),callback:function ($$v) {_vm.$set(_vm.record, "symbolsPerPage", $$v)},expression:"record.symbolsPerPage"}}):_vm._e(),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3601244393)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.calcType==='isPerWord')?_c('v-text-field',{attrs:{"label":_vm.$t('reference-dialogs.wpp'),"clearable":""},model:{value:(_vm.record.wordsPerPage),callback:function ($$v) {_vm.$set(_vm.record, "wordsPerPage", $$v)},expression:"record.wordsPerPage"}}):_vm._e(),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1903138797)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.calcType==='isFixedPrice')?_c('v-text-field',{attrs:{"label":_vm.$t('reference-dialogs.fixed-price'),"clearable":""},model:{value:(_vm.record.fixedPriceValue),callback:function ($$v) {_vm.$set(_vm.record, "fixedPriceValue", $$v)},expression:"record.fixedPriceValue"}}):_vm._e(),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1310242759)})],1)],1):_vm._e(),(_vm.action==='create')?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('reference-dialogs.calc-type'),"clearable":""},on:{"change":function($event){return _vm.$emit('new-record',_vm.newRecord)}},model:{value:(_vm.newRecord.calculationTypeName),callback:function ($$v) {_vm.$set(_vm.newRecord, "calculationTypeName", $$v)},expression:"newRecord.calculationTypeName"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,335823006)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-radio-group',{attrs:{"row":""},on:{"change":function($event){return _vm.selectType()}},model:{value:(_vm.calcType),callback:function ($$v) {_vm.calcType=$$v},expression:"calcType"}},_vm._l((_vm.options),function(option){return _c('v-radio',{key:option.id,attrs:{"label":option.label,"value":option.calcType},on:{"change":function($event){return _vm.$emit('new-record',_vm.newRecord)}}})}),1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.calcType==='isPerSymbol')?_c('v-text-field',{attrs:{"label":_vm.$t('reference-dialogs.spp'),"clearable":""},on:{"change":function($event){return _vm.$emit('new-record',_vm.newRecord)}},model:{value:(_vm.newRecord.symbolsPerPage),callback:function ($$v) {_vm.$set(_vm.newRecord, "symbolsPerPage", $$v)},expression:"newRecord.symbolsPerPage"}}):_vm._e(),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1497944494)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.calcType==='isPerWord')?_c('v-text-field',{attrs:{"label":_vm.$t('reference-dialogs.wpp'),"clearable":""},on:{"change":function($event){return _vm.$emit('new-record',_vm.newRecord)}},model:{value:(_vm.newRecord.wordsPerPage),callback:function ($$v) {_vm.$set(_vm.newRecord, "wordsPerPage", $$v)},expression:"newRecord.wordsPerPage"}}):_vm._e(),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3979795882)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.calcType==='isFixedPrice')?_c('v-text-field',{attrs:{"label":_vm.$t('reference-dialogs.fixed-price'),"clearable":""},on:{"change":function($event){return _vm.$emit('new-record',_vm.newRecord)}},model:{value:(_vm.newRecord.fixedPriceValue),callback:function ($$v) {_vm.$set(_vm.newRecord, "fixedPriceValue", $$v)},expression:"newRecord.fixedPriceValue"}}):_vm._e(),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2912501504)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }