var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ReferenceSearchTab',{attrs:{"search":_vm.search,"open-dialog":_vm.editDialog,"objectName":_vm.objectName},on:{"search":_vm.performSearch,"open-create":_vm.createItem}}),_c('EditDialog',{attrs:{"open-dialog":_vm.editDialog,"objectType":_vm.objectType,"record":_vm.record,"imgName":_vm.imgName,"action":_vm.action},on:{"close-dialog":function($event){return _vm.closeDialog()},"save-record":function($event){return _vm.saveRecord($event)},"create-record":function($event){return _vm.createRecord($event)}}}),_c('v-data-table',{attrs:{"loading":_vm.loading,"dense":"","item-key":"calculationTypeId","loader-height":"5","loading-text":_vm.$t('loadings.loading-calc-types'),"no-data-text":_vm.$t('loadings.no-calc-types-found'),"multi-sort":"","headers":_vm.headers,"items":_vm.calculationTypes,"search":_vm.search,"footer-props":{
              showFirstLastPage: true,
              itemsPerPageOptions: [ 5, 10, 15, 50, -1]}},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
              var item = ref.item;
return [_c('v-switch',{attrs:{"inset":""},on:{"change":function($event){return _vm.activateItem(item.calculationTypeId, item.active)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.perSymbol",fn:function(ref){
              var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.perSymbol),callback:function ($$v) {_vm.$set(item, "perSymbol", $$v)},expression:"item.perSymbol"}})]}},{key:"item.fixedPrice",fn:function(ref){
              var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.fixedPrice),callback:function ($$v) {_vm.$set(item, "fixedPrice", $$v)},expression:"item.fixedPrice"}})]}},{key:"item.perWord",fn:function(ref){
              var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.perWord),callback:function ($$v) {_vm.$set(item, "perWord", $$v)},expression:"item.perWord"}})]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }