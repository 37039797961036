<template>
  <v-container>
    <ReferenceSearchTab :search="search" v-on:search="performSearch" :open-dialog="editDialog"
                        v-on:open-create="createItem" :objectName="objectName"></ReferenceSearchTab>
    <EditDialog v-bind:open-dialog="editDialog" v-on:close-dialog="closeDialog()" :objectType="objectType"
                :record="record" :imgName="imgName" v-on:save-record="saveRecord($event)" :action="action"
                v-on:create-record="createRecord($event)"></EditDialog>
    <v-data-table
      :loading="loading"
      dense
      item-key="orderTypeId"
      loader-height="5"
      :loading-text="$t('loadings.loading-order-types')"
      :no-data-text="$t('loadings.no-order-types-found')"
      multi-sort
      :headers="headers"
      :items="orderTypes"
      :search="search"
      :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions: [ 5, 10, 15, 50, -1]}">
      <template v-slot:item.isActive="{ item }">
        <v-switch inset v-on:change="activateItem(item.orderTypeId, item.active)"
                  v-model="item.active">
        </v-switch>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import ReferenceSearchTab from "./ReferenceSearchTab";
  import EditDialog from "./reference-dialogs/EditDialog";
  import {AXIOS} from "../../../http-commons";

  let moment = require('moment');
  export default {
    components: {ReferenceSearchTab, EditDialog},
    props: ['orderTypes', 'loading'],
    name: "OrderTypesTab",
    methods: {
      performSearch(searchValue) {
        this.search = searchValue
      },
      closeDialog() {
        this.editDialog = false
      },
      editItem(item) {
        this.editDialog = true
        this.objectType = 'orderType'
        this.imgName = 'editOrderTypeDialog.jpg'
        this.record = item
        this.action = 'edit'
      },
      createItem() {
        this.editDialog = true
        this.objectType = 'orderType'
        this.imgName = 'editOrderTypeDialog.jpg'
        this.action = 'create'
      },
      activateItem(orderTypeId, active) {
        let action = active ? 'activate' : 'deactivate';
        let params = new URLSearchParams();
        params.append('isActive', active);
        params.append('orderTypeId', orderTypeId);
        AXIOS.put(`/api/activateOrderType`, params)
          .then(response => {
            this.userNotification(response.data, action, this.objectName);
          })
      },
      saveRecord(record) {
        let action = 'update';
        let params = new URLSearchParams();
        params.append('orderTypeName', record.orderTypeName);
        params.append('active', record.active);
        params.append('deleted', record.deleted);
        params.append('orderTypeId', record.orderTypeId);
        AXIOS.put(`/api/updateOrderType`, params)
          .then(response => {
            this.editDialog = false;
            this.userNotification(response.data, action, this.objectName);
          })
      },
      createRecord(record) {
        let action = 'create';
        let params = new URLSearchParams();
        params.append('orderTypeName', record.orderTypeName);
        params.append('userId', this.currentUser.id);
        AXIOS.post(`/api/createOrderType`, params)
          .then(response => {
            this.editDialog = false;
            this.userNotification(response.data, action, this.objectName);
            this.orderTypes.push(response.data);
          })
      },
    },
    data() {
      return {
        objectName: 'Order type',
        action: '',
        editDialog: false,
        objectType: '',
        imgName: '',
        record: {},
        search: '',
        headers: [
          {
            text: this.$t('tables.active'),
            align: 'start',
            value: 'isActive',
            class: "primary--text",
          },
          {
            text: this.$t('tables.orderType'),
            value: 'orderTypeName',
            class: "primary--text",
          },
          {text: this.$t('tables.actions'), value: 'actions', class: "primary--text", sortable: false},
        ],
      }
    },
  }
</script>

<style scoped>

</style>
