<template>
  <v-row justify="center">
    <v-dialog v-model="openDialog" persistent max-width="900px">
      <v-card>
        <v-container>
          <v-img v-if="imgName!==''" :src="require(`@/assets/UI-images/${imgName}`)" max-height="100px"></v-img>
          <validation-observer ref="observer" v-slot="{ invalid }">
            <LangPairFieldset v-if="objectType==='langPair'" :record="record" :action="action"
                              v-on:new-record="newRecord = $event"></LangPairFieldset>
            <TopicFieldset v-if="objectType==='topic'" :record="record" :action="action"
                           v-on:new-record="newRecord = $event"></TopicFieldset>
            <OrderTypeFieldset v-if="objectType==='orderType'" :record="record" :action="action"
                               v-on:new-record="newRecord = $event"></OrderTypeFieldset>
            <CalculationTypeFieldset v-if="objectType==='calculationType'" :record="record"
                                     :action="action" v-on:new-record="newRecord = $event"></CalculationTypeFieldset>
            <CatToolFieldset v-if="objectType==='catTool'" :record="record" :action="action"
                             v-on:new-record="newRecord = $event"></CatToolFieldset>
            <EmailTemplateFieldset v-if="objectType==='template'" :record="record"
                                   :action="action" v-on:new-record="newRecord = $event"></EmailTemplateFieldset>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text v-on:click="$emit('close-dialog')">
                {{$t('buttons.cancel')}}
              </v-btn>
              <v-btn color="primary"
                     v-on:click="action==='edit'?$emit('save-record',record):$emit('create-record', newRecord)"
                     :disabled="invalid">
                {{$t('buttons.save')}}
              </v-btn>
            </v-card-actions>
          </validation-observer>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import LangPairFieldset from "./LangPairFieldset";
  import TopicFieldset from "./TopicFieldset";
  import OrderTypeFieldset from "./OrderTypeFieldset";
  import CatToolFieldset from "./CatToolFieldset";
  import EmailTemplateFieldset from "./EmailTemplateFieldset";
  import CalculationTypeFieldset from "./CalculationTypeFieldset";
  import {ValidationObserver} from 'vee-validate';

  export default {
    components: {
      EmailTemplateFieldset,
      LangPairFieldset,
      TopicFieldset,
      OrderTypeFieldset,
      CatToolFieldset,
      CalculationTypeFieldset,
      ValidationObserver
    },
    props: ['openDialog', 'record', 'imgName', 'objectType', 'action'],
    name: "EditDialog",
    data() {
      return {
        newRecord: {},
      }
    },
  }
</script>
