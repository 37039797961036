<template>
  <v-container>
    <v-card-title>
      <span class="headline">{{action==='edit'?record.fullPairName:$t('reference-dialogs.create-lang-pair')}}</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row v-if="action==='edit'">
        <v-col cols="12" sm="12" md="12">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="record.fullPairName" :label="$t('reference-dialogs.full-pair-name')" clearable/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="record.shortPairName" :label="$t('reference-dialogs.short-pair-name')" clearable/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="record.sourceLang" :label="$t('reference-dialogs.source-lang')" clearable/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="record.targetLang" :label="$t('reference-dialogs.target-lang')" clearable/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row v-if="action==='create'">
        <v-col cols="12" sm="12" md="12">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="newRecord.fullPairName" label="Full pair name" clearable
              v-on:change="$emit('new-record',newRecord)"/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="newRecord.shortPairName" label="Short pair name" clearable
              v-on:change="$emit('new-record',newRecord)"/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="newRecord.sourceLang" label="Source language" clearable
              v-on:change="$emit('new-record',newRecord)"/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="newRecord.targetLang" label="Target language" clearable
              v-on:change="$emit('new-record',newRecord)"/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>

<script>
  import {ValidationProvider, extend, ValidationObserver} from 'vee-validate';
  import {required} from "vee-validate/dist/rules";

  extend('required', {
    ...required,
    message: 'The field is required'
  });

  export default {
    name: "LangPairFieldset",
    components: {ValidationProvider, ValidationObserver},
    props: ['record', 'action'],
    data() {
      return {
        newRecord: {
          fullPairName: '',
          shortPairName: '',
          sourceLang: '',
          targetLang: ''
        },
      }
    },
  }
</script>

<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
