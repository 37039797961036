<template>
  <v-container>
    <v-card-title>
      <span class="headline">{{action==='edit'?record.topicName:$t('reference-dialogs.create-topic')}}</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row v-if="action==='edit'">
        <v-col cols="12" sm="12" md="12">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field v-model="record.topicName" :label="$t('reference-dialogs.topic')" clearable/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
          <v-text-field v-model="record.description" :label="$t('reference-dialogs.description')"
                        clearable/>
        </v-col>
      </v-row>

      <v-row v-if="action==='create'">
        <v-col cols="12" sm="12" md="12">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field v-model="newRecord.topicName" :label="$t('reference-dialogs.topic')" clearable
                          v-on:change="$emit('new-record',newRecord)"/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
          <v-text-field v-model="newRecord.description" :label="$t('reference-dialogs.description')" clearable
                        v-on:change="$emit('new-record',newRecord)"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>

<script>
  import {ValidationObserver, ValidationProvider, extend} from "vee-validate";
  import {required} from "vee-validate/dist/rules";

  extend('required', {
    ...required,
    message: 'The field is required'
  });

  export default {
    components: {ValidationProvider, ValidationObserver},
    name: "TopicFieldset",
    props: ['record', 'action'],
    data() {
      return {
        newRecord: {
          topicName: '',
          description: ''
        },
      }
    },
  }
</script>

<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
