<template>
  <v-container>
    <v-card-title>
      <span class="headline">{{action==='edit'?record.calculationTypeName:$t('reference-dialogs.create-calc-type')}}</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>

      <v-row v-if="action==='edit'">
        <v-col cols="12" sm="12" md="12">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field v-model="record.calculationTypeName" :label="$t('reference-dialogs.calc-type')" clearable/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
        </v-col>


        <v-col cols="12" sm="12" md="12">
          <p style="visibility: hidden">{{defineType}}</p>
          <v-radio-group row v-model="calcType" v-on:change="selectType()">
            <v-radio v-for="option in options" :key="option.id" :label="option.label"
                     :value="option.calcType"></v-radio>
          </v-radio-group>
        </v-col>


        <v-col cols="12" sm="12" md="12">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field v-if="calcType==='isPerSymbol'" v-model="record.symbolsPerPage" :label="$t('reference-dialogs.spp')"
                          clearable/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field v-if="calcType==='isPerWord'" v-model="record.wordsPerPage" :label="$t('reference-dialogs.wpp')" clearable/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field v-if="calcType==='isFixedPrice'" v-model="record.fixedPriceValue" :label="$t('reference-dialogs.fixed-price')"
                          clearable/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row v-if="action==='create'">
        <v-col cols="12" sm="12" md="12">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field v-model="newRecord.calculationTypeName" :label="$t('reference-dialogs.calc-type')" clearable
                          v-on:change="$emit('new-record',newRecord)"/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-radio-group v-model="calcType" row v-on:change="selectType()">
            <v-radio v-for="option in options" :key="option.id" :label="option.label" :value="option.calcType"
                     v-on:change="$emit('new-record',newRecord)"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field v-if="calcType==='isPerSymbol'"
                          v-model="newRecord.symbolsPerPage" :label="$t('reference-dialogs.spp')" clearable
                          v-on:change="$emit('new-record',newRecord)"/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field v-if="calcType==='isPerWord'"
                          v-model="newRecord.wordsPerPage" :label="$t('reference-dialogs.wpp')" clearable
                          v-on:change="$emit('new-record',newRecord)"/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field v-if="calcType==='isFixedPrice'"
                          v-model="newRecord.fixedPriceValue" :label="$t('reference-dialogs.fixed-price')" clearable
                          v-on:change="$emit('new-record',newRecord)"/>
            <span class="red--text font-weight-light">{{ errors[0] }}</span>
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>

<script>
  import {ValidationProvider, extend, ValidationObserver} from 'vee-validate';
  import {required} from "vee-validate/dist/rules";

  extend('required', {
    ...required,
    message: 'The field is required'
  });
  export default {
    name: "CalculationTypeFieldset",
    props: ['record', 'action'],
    components: {ValidationProvider, ValidationObserver},
    data() {
      return {
        calcType: '',
        newRecord: [],
        options: [
          {id: 1, label: this.$t('reference-dialogs.per-symbols'), value: 'isPerSymbol', calcType: 'isPerSymbol'},
          {id: 2, label: this.$t('reference-dialogs.per-words'), value: 'isPerWord', calcType: 'isPerWord'},
          {id: 3, label: this.$t('reference-dialogs.fixed-price'), value: 'isFixedPrice', calcType: 'isFixedPrice'},
        ]
      }
    },
    computed: {
      defineType: function () {
        let rec = this.record;
        if (rec.perSymbol) {
          return this.calcType = 'isPerSymbol'
        }
        if (rec.perWord) {
          return this.calcType = 'isPerWord'
        }
        if (rec.fixedPrice) {
          return this.calcType = 'isFixedPrice'
        }
      },
    },
    methods: {
      selectType() {
        console.log("type: " + this.calcType)
        let rec;
        if (this.action === 'create') {
          console.log("create")
          rec = this.newRecord;
        }
        if (this.action === 'edit') {
          rec = this.record;
        }
        if (this.calcType === 'isPerSymbol') {
          console.log("sym")
          rec.perSymbol = true;
          rec.perWord = false;
          rec.fixedPrice = false;
          rec.fixedPriceValue = 0;
          rec.wordsPerPage = 0;
          console.log("type: " + this.calcType)
        }
        if (this.calcType === 'isPerWord') {
          console.log("word")
          rec.perSymbol = false;
          rec.perWord = true;
          rec.fixedPrice = false;
          rec.fixedPriceValue = 0;
          rec.symbolsPerPage = 0;
          console.log("type: " + this.calcType)
        }
        if (this.calcType === 'isFixedPrice') {
          console.log("fix")
          rec.perSymbol = false;
          rec.perWord = false;
          rec.fixedPrice = true;
          rec.wordsPerPage = 0;
          rec.symbolsPerPage = 0;
          console.log("type: " + this.calcType)
        }
      }
    }
  }
</script>
<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
