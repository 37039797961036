<template>
  <v-container>
    <ReferenceSearchTab :search="search" v-on:search="performSearch" :open-dialog="editDialog"
                        v-on:open-create="createItem" :objectName="objectName"></ReferenceSearchTab>
    <EditDialog v-bind:open-dialog="editDialog" v-on:close-dialog="closeDialog()" :objectType="objectType"
                :record="record" :imgName="imgName" v-on:save-record="saveRecord($event)" :action="action"
                v-on:create-record="createRecord($event)"></EditDialog>
    <v-data-table
      :loading="loading"
      dense
      item-key="langPairId"
      loader-height="5"
      :loading-text="$t('loadings.loading-langs')"
      :no-data-text="$t('loadings.no-langs-found')"
      multi-sort
      :headers="headers"
      :items="langPairs"
      :search="search"
      :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions: [ 5, 10, 15, 50, -1]}">
      <template v-slot:item.isActive="{ item }">
        <v-switch inset v-on:change="activateItem(item.langPairId, item.active)"
                  v-model="item.active">
        </v-switch>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import ReferenceSearchTab from "./ReferenceSearchTab";
  import EditDialog from "./reference-dialogs/EditDialog";
  import {AXIOS} from "../../../http-commons";

  let moment = require('moment');
  export default {
    components: {ReferenceSearchTab, EditDialog},
    props: ['langPairs', 'loading'],
    name: "LanguageTab",
    methods: {
      closeDialog() {
        this.editDialog = false
      },
      performSearch(searchValue) {
        this.search = searchValue
      },
      editItem(item) {
        this.editDialog = true
        this.objectType = 'langPair'
        this.imgName = 'editLangDialog.jpg'
        this.record = item
        this.action = 'edit'
      },
      createItem() {
        this.editDialog = true
        this.objectType = 'langPair'
        this.imgName = 'editLangDialog.jpg'
        this.action = 'create'
      },
      activateItem(langPairId, active) {
        let params = new URLSearchParams();
        let action = active ? 'activate' : 'deactivate';
        params.append('isActive', active);
        params.append('langPairId', langPairId);
        AXIOS.put(`/api/activateLanguage`, params)
          .then(response => {
            this.userNotification(response.data, action, this.objectName);
          })
      },
      saveRecord(record) {
        let action = 'update';
        let params = new URLSearchParams();
        params.append('fullPairName', record.fullPairName);
        params.append('shortPairName', record.shortPairName);
        params.append('active', record.active);
        params.append('deleted', record.deleted);
        params.append('sourceLang', record.sourceLang);
        params.append('targetLang', record.targetLang);
        params.append('langPairId', record.langPairId);
        AXIOS.put(`/api/updateLanguage`, params)
          .then(response => {
            this.editDialog = false;
            this.userNotification(response.data, action, this.objectName);
          })
      },
      createRecord(record) {
        let action = 'create';
        let params = new URLSearchParams();
        params.append('userId', this.currentUser.id);
        params.append('fullPairName', record.fullPairName);
        params.append('shortPairName', record.shortPairName);
        params.append('sourceLang', record.sourceLang);
        params.append('targetLang', record.targetLang);
        AXIOS.post(`/api/createLanguage/`, params)
          .then(response => {
            this.editDialog = false;
            this.userNotification(response.data, action, this.objectName);
            this.langPairs.push(response.data)
          })
      },
    },
    data() {
      return {
        objectName: 'Language',
        editDialog: false,
        search: '',
        objectType: '',
        imgName: '',
        action: '',
        record: null,
        headers: [
          {
            text: this.$t('tables.active'),
            align: 'start',
            value: 'isActive',
            class: "primary--text",
          },
          {
            text: this.$t('tables.sourceLang'),
            value: 'sourceLang',
            class: "primary--text",
          },
          {text: this.$t('tables.targetLang'), value: 'targetLang', class: "primary--text"},
          {text: this.$t('tables.shortPairName'), value: 'shortPairName', class: "primary--text"},
          {text: this.$t('tables.actions'), value: 'actions', class: "primary--text", sortable: false},
        ],
      }
    },
  }
</script>
