<template>
  <v-container>
    <ReferenceSearchTab :search="search" v-on:search="performSearch" :open-dialog="editDialog"
                        v-on:open-create="createItem" :objectName="objectName"></ReferenceSearchTab>
    <EditDialog v-bind:open-dialog="editDialog" v-on:close-dialog="closeDialog()" :objectType="objectType"
                :record="record" :imgName="imgName" v-on:save-record="saveRecord($event)" :action="action"
                v-on:create-record="createRecord($event)"></EditDialog>
    <v-data-table
      :loading="loading"
      dense
      item-key="toolId"
      loader-height="5"
      :loading-text="$t('loadings.loading-cat-tools')"
      :no-data-text="$t('loadings.no-cat-tools-found')"
      multi-sort
      :headers="headers"
      :items="catTools"
      :search="search"
      :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions: [ 5, 10, 15, 50, -1]}">
      <template v-slot:item.isActive="{ item }">
        <v-switch inset v-on:change="activateItem(item.toolId, item.active)"
                  v-model="item.active">
        </v-switch>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import ReferenceSearchTab from "./ReferenceSearchTab";
  import EditDialog from "./reference-dialogs/EditDialog";
  import {AXIOS} from "../../../http-commons";

  let moment = require('moment');
  export default {
    components: {ReferenceSearchTab, EditDialog},
    props: ['catTools', 'loading'],
    name: "CatToolsTab",
    methods: {
      performSearch(searchValue) {
        this.search = searchValue
      },
      closeDialog() {
        this.editDialog = false
      },
      editItem(item) {
        this.editDialog = true
        this.objectType = 'catTool'
        this.imgName = 'editCatToolDialog.png'
        this.record = item
        this.action = 'edit'
      },
      createItem() {
        this.editDialog = true
        this.objectType = 'catTool'
        this.imgName = 'editCatToolDialog.png'
        this.action = 'create'
      },
      activateItem(toolId, active) {
        let params = new URLSearchParams();
        let action = active ? 'activate' : 'deactivate';
        params.append('isActive', active);
        params.append('toolId', toolId);
        AXIOS.put(`/api/activateCatTool`, params)
          .then(response => {
            this.userNotification(response.data, action, this.objectName);
          })
      },
      saveRecord(record) {
        let params = new URLSearchParams();
        let action = 'update';
        params.append('toolName', record.toolName);
        params.append('active', record.active);
        params.append('deleted', record.deleted);
        params.append('toolId', record.toolId);
        AXIOS.put(`/api/updateCatTool`, params)
          .then(response => {
            this.editDialog = false;
            this.userNotification(response.data, action, this.objectName);
          })
      },
      createRecord(record) {
        let action = 'create';
        let params = new URLSearchParams();
        params.append('toolName', record.toolName);
        params.append('userId', this.currentUser.id);
        AXIOS.post(`/api/createCatTool`, params)
          .then(response => {
            this.editDialog = false;
            this.catTools.push(response.data)
            this.userNotification(response.data, action, this.objectName);
          })
      },
    },
    data() {
      return {
        objectName: 'CAT tool',
        action: '',
        editDialog: false,
        objectType: '',
        imgName: '',
        record: null,
        search: '',
        headers: [
          {
            text: this.$t('tables.active'),
            align: 'start',
            value: 'isActive',
            class: "primary--text",
          },
          {
            text: this.$t('tables.toolName'),
            value: 'toolName',
            class: "primary--text",
          },
          {text: this.$t('tables.actions'), value: 'actions', class: "primary--text", sortable: false},
        ],
      }
    },
  }
</script>

<style scoped>

</style>
