<template>
  <v-container>
    <ReferenceSearchTab :search="search" v-on:search="performSearch" :open-dialog="editDialog"
                        v-on:open-create="createItem" :objectName="objectName"></ReferenceSearchTab>
    <EditDialog v-bind:open-dialog="editDialog" v-on:close-dialog="closeDialog()" :objectType="objectType"
                :record="record" :imgName="imgName" v-on:save-record="saveRecord($event)" :action="action"
                v-on:create-record="createRecord($event)"></EditDialog>
    <v-data-table
      :loading="loading"
      dense
      item-key="calculationTypeId"
      loader-height="5"
      :loading-text="$t('loadings.loading-calc-types')"
      :no-data-text="$t('loadings.no-calc-types-found')"
      multi-sort
      :headers="headers"
      :items="calculationTypes"
      :search="search"
      :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions: [ 5, 10, 15, 50, -1]}">
      <template v-slot:item.isActive="{ item }">
        <v-switch inset v-on:change="activateItem(item.calculationTypeId, item.active)"
                  v-model="item.active">
        </v-switch>
      </template>
      <template v-slot:item.perSymbol="{ item }">
        <v-checkbox
          v-model="item.perSymbol" disabled>
        </v-checkbox>
      </template>
      <template v-slot:item.fixedPrice="{ item }">
        <v-checkbox
          v-model="item.fixedPrice" disabled>
        </v-checkbox>
      </template>
      <template v-slot:item.perWord="{ item }">
        <v-checkbox
          v-model="item.perWord" disabled>
        </v-checkbox>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import ReferenceSearchTab from "./ReferenceSearchTab";
  import EditDialog from "./reference-dialogs/EditDialog";
  import {AXIOS} from "../../../http-commons";

  let moment = require('moment');
  export default {
    components: {ReferenceSearchTab, EditDialog},
    props: ['calculationTypes', 'loading'],
    name: "CalculationTypesTab",
    methods: {
      performSearch(searchValue) {
        this.search = searchValue
      },
      closeDialog() {
        this.editDialog = false
      },
      editItem(item) {
        this.editDialog = true
        this.objectType = 'calculationType'
        this.imgName = 'editCalculationTypeDialog.jpg'
        this.record = item
        this.action = 'edit'
      },
      createItem() {
        this.editDialog = true
        this.objectType = 'calculationType'
        this.imgName = 'editCalculationTypeDialog.jpg'
        this.action = 'create'
      },
      activateItem(calculationTypeId, active) {
        let params = new URLSearchParams();
        let action = active ? 'activate' : 'deactivate';
        params.append('calculationTypeId', calculationTypeId);
        params.append('isActive', active);
        AXIOS.put(`/api/activateCalculationType`, params)
          .then(response => {
            this.userNotification(response.data, action, this.objectName);
          })
      },
      saveRecord(record) {
        console.log("rec: ", record)
        let params = new URLSearchParams();
        let action = 'update';
        params.append('calculationTypeName', record.calculationTypeName);
        params.append('active', record.active);
        params.append('deleted', record.deleted);
        params.append('perWord', record.perWord);
        params.append('perSymbol', record.perSymbol);
        params.append('fixedPrice', record.fixedPrice);
        params.append('symbolsPerPage', record.symbolsPerPage);
        params.append('wordsPerPage', record.wordsPerPage);
        params.append('fixedPriceValue', record.fixedPriceValue);
        params.append('calculationTypeId', record.calculationTypeId);
        AXIOS.put(`/api/updateCalculationType`, params)
          .then(response => {
            this.editDialog = false;
            this.userNotification(response.data, action, this.objectName);
          })
      },
      createRecord(record) {
        console.log("rec: ", record)
        let action = 'create';
        let params = new URLSearchParams();
        params.append('userId', this.currentUser.id);
        params.append('calculationTypeName', record.calculationTypeName);
        params.append('perWord', record.perWord);
        params.append('perSymbol', record.perSymbol);
        params.append('fixedPrice', record.fixedPrice);
        params.append('symbolsPerPage', record.symbolsPerPage);
        params.append('wordsPerPage', record.wordsPerPage);
        params.append('fixedPriceValue', record.fixedPriceValue);
        AXIOS.post(`/api/createCalculationType`, params)
          .then(response => {
            this.editDialog = false;
            this.calculationTypes.push(response.data)
            this.userNotification(response.data, action, this.objectName);
          })
      },
    },
    data() {
      return {
        objectName: 'Calculation type',
        action: '',
        editDialog: false,
        objectType: '',
        imgName: '',
        record: null,
        search: '',
        headers: [
          {
            text: this.$t('tables.active'),
            align: 'start',
            value: 'isActive',
            class: "primary--text",
          },
          {
            text: this.$t('tables.calculationType'),
            value: 'calculationTypeName',
            class: "primary--text",
          },
          {
            text: this.$t('tables.symbolsPerPage'),
            value: 'symbolsPerPage',
            class: "primary--text",
          },
          {
            text: this.$t('tables.wordsPerPage'),
            value: 'wordsPerPage',
            class: "primary--text",
          },
          {
            text: this.$t('tables.fixedPrice'),
            value: 'fixedPriceValue',
            class: "primary--text",
          },
          {
            text: this.$t('tables.perSymbol'),
            value: 'perSymbol',
            class: "primary--text",
          },
          {
            text: this.$t('tables.perWord'),
            value: 'perWord',
            class: "primary--text",
          },
          {
            text: this.$t('tables.perFixedPrice'),
            value: 'fixedPrice',
            class: "primary--text",
          },
          {text: this.$t('tables.actions'), value: 'actions', class: "primary--text", sortable: false},
        ],
      }
    },
  }
</script>

<style scoped>

</style>
