<template>
  <v-container>
    <v-card-title>
      <span v-if="action==='edit'" class="headline">Template: {{record.templateName}}</span>
      <span v-if="action==='create'" class="headline">Create new template</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row v-if="action==='edit'">
        <v-autocomplete v-on:change="getObjectFields()"
                        v-model="selectedType" :items="objectTypes" label="Objects">
        </v-autocomplete>
        <v-autocomplete v-on:change="createTextToCopy()" v-model="selectedField"
                        :items="objectFields" label="Fields">
        </v-autocomplete>
        <v-text-field
          v-model="textToCopy" label="Copy this text"/>
        <v-col
          cols="12"
          sm="12"
          md="12">
          <v-text-field
            v-model="record.templateName" label="Template name" clearable/>
          <v-textarea
            v-model="record.description" label="Description (email body)" clearable counter rows="5"/>
        </v-col>
      </v-row>

      <v-row v-if="action==='create'">
        <v-autocomplete v-on:change="getObjectFields()"
                        v-model="selectedType" :items="objectTypes" label="Objects">
        </v-autocomplete>
        <v-autocomplete v-on:change="createTextToCopy()" v-model="selectedField"
                        :items="objectFields" label="Fields">
        </v-autocomplete>
        <v-text-field
          v-model="textToCopy" label="Copy this text"/>
        <v-col
          cols="12"
          sm="12"
          md="12">
          <v-text-field
            v-model="newRecord.templateName" label="Template name" clearable/>
          <v-textarea
            v-model="newRecord.description" label="Description (email body)" clearable counter rows="5"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>

<script>
  import {AXIOS} from "../../../../http-commons";

  export default {
    name: "EmailTemplateFieldset",
    props: ['record', 'action', 'newRecord'],
    data() {
      return {
        objectFields: [],
        textToCopy: '',
        selectedType: '',
        selectedField: '',
        objectTypes: ['Order', 'Agency','User'],
      }
    },
    methods: {
      getObjectFields() {
        AXIOS.get('/api/getAllFields/' + this.selectedType)
          .then(response => {
            this.objectFields = response.data;
          })
      },
      createTextToCopy() {
        this.textToCopy = '{{' + this.selectedType.toLowerCase() + '.' + this.selectedField + '}}'
      }
    }
  }
</script>
