<template>
  <v-row class="ma-auto">
    <v-col cols="8" md="4" sm="6">
      <v-text-field
        v-on:input="$emit('search',searchValue)"
        v-model="searchValue"
        append-icon="mdi-magnify"
        :label="$t('search')"
        single-line
        clearable
        hide-details>
      </v-text-field>
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="4" md="4" sm="4">
      <v-btn style="float: right"
             v-on:click="objectName==='Agency'?$router.push({ path:'/createagency' }):$emit('open-create')"
             color="primary">{{$t('buttons.create')}}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
  export default {
    props: ['search', 'objectName'],
    name: "ReferenceSearchTab",
    data() {
      return {
        searchValue: '',
      }
    },
  }
</script>
